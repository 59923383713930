var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", [
    _c("iframe", {
      staticStyle: { border: "none" },
      attrs: { src: _vm.safeUrl, width: "100%", height: "600px" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }